import React, { lazy } from 'react'
import { Route, Routes } from "react-router-dom";


import Dashboard from "./pages/Dashboard/Dashboard";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';






const Revenue = lazy(()=>import("./pages/Dashboard/Revenue/Revenue"));
const Records = lazy(()=>import("./pages/Dashboard/Records/Records"));
const Instructors = lazy(()=>import("./pages/Dashboard/Instructors/Instructors"));
const InstructorRecords = lazy(()=>import("./pages/Dashboard/InstructorRecords/InstructorRecords"));
const InstructorRecords2 = lazy(()=>import("./pages/InstructorRecords/InstructorRecords"));
const StudentRecords = lazy(()=>import("./pages/Dashboard/StudentsRecords/StudentRecords"));
const InstructorRecordDataTable = lazy(() => import('./components/InstructorRecordDataTable/InstructorRecordDataTable'));


function App() {



  return (
    <Routes>
          <Route path="/signin" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>

          <Route path="instructor-records/" element={<InstructorRecords2/>}/>
          <Route path="instructor-record-data/:recordId" element={<InstructorRecordDataTable/>}/>

          
          
      <Route path="/" element={<Dashboard/>}>
          <Route index element={<Revenue/>}/>
          <Route path="records" element={<Records/>}/>
          <Route path="instructors" element={<Instructors/>}/>
          <Route path="instructor-records/:instructor_id" element={<InstructorRecords/>}/>
          <Route path="student-records/:student_id" element={<StudentRecords/>}/>
          
      </Route>

      <Route path="*" element={<PageNotFound/>}/>
    </Routes>
  );
}

export default App;
