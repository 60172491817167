import React from 'react'
import { Link } from 'react-router-dom'

function Alert({type, text, link, linkTitle='go back home'}) {
    return (

        <div className={`alert alert__${type}`}>
             {text}
             {link&&    <Link to={link}> {linkTitle}</Link>}
        </div>

    )
}

export default Alert
