import { publicApiSlice } from '../api/publicApiSlice'



export const coursePublicApiSlice = publicApiSlice.injectEndpoints({
    endpoints: builder => ({
      getSubjects: builder.query({
        query: () => `/administration/subjects/`,
      }),
      search: builder.query({
        query: (query) => `/administration/search/?query=${query}`,
      }),
      getRecords: builder.query({
        query: ({month,year}) => `/instructors/records/?month=${month}&year=${year}`,
        providesTags: (result, error, arg) => [{ type: 'Rcords', id: 'LIST' }]
      }),
      getRevenue: builder.query({
        query: ({month,year}) => `/instructors/revnue/?month=${month}&year=${year}`,
        providesTags: (result, error, arg) => [{ type: 'Revenue', id: 'LIST' }]
      }),
      getInstructors: builder.query({
        query: () => `/instructors/`,
        providesTags: (result, error, arg) => [{ type: 'Instructors', id: 'LIST' }]
      }),
      getInstructorRecords: builder.query({
        query: ({instructor_id, month, year}) => `/instructors/instructor-records/${instructor_id}/?month=${month}&year=${year}`,
        providesTags: (result, error, arg) => [{ type: 'InstructorRecords', id: arg.instructor_id }]
      }),
      getStudentsRecords: builder.query({
        query: (student_id) => `/students/student-records/${student_id}/`,
      }),
      createRecord: builder.mutation({
        query: ({recordObj}) => ({
          url: `/instructors/records/`,
          method: 'POST',
          body: recordObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'InstructorRecords', id: arg.instructor_id }]
      }),
      updateRecord: builder.mutation({
        query: ({recordObj, record_id}) => ({
          url: `/instructors/records/${record_id}/`,
          method: 'PATCH',
          body: recordObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'InstructorRecords', id: arg.instructor_id }]
      }),
      deleteRecord: builder.mutation({
        query: ({record_id}) => ({
          url: `/instructors/records/${record_id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'InstructorRecords', id: arg.instructor_id }]
      }),
      migrateRecords: builder.mutation({
        query: ({recordObj}) => ({
          url: `/instructors/migrate-data/`,
          method: 'POST',
          body: recordObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'InstructorRecords', id: arg.instructor_id }]
      }),
      createInstructor: builder.mutation({
        query: (instructorObj) => ({
          url: `/instructors/`,
          method: 'POST',
          body: instructorObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Instructors', id: 'LIST' }]
      }),
      updateInstructor: builder.mutation({
        query: ({instructorObj, instructor_id}) => ({
          url: `/instructors/${instructor_id}/`,
          method: 'PATCH',
          body: instructorObj
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Instructors', id: 'LIST' }]
      }),
      deleteInstructor: builder.mutation({
        query: (instructor_id) => ({
          url: `/instructors/${instructor_id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Instructors', id: 'LIST' }]
      }),
    })
  })
  




  export const { 
    useGetSubjectsQuery,
    useGetRecordsQuery,
    useGetRevenueQuery,
    useGetInstructorRecordsQuery,
    useCreateRecordMutation,
    useUpdateRecordMutation,
    useDeleteRecordMutation,
    useMigrateRecordsMutation,
    useCreateInstructorMutation,
    useUpdateInstructorMutation,
    useDeleteInstructorMutation,
    useGetInstructorsQuery,
    useSearchQuery,
    useGetStudentsRecordsQuery,
   } = coursePublicApiSlice;

