import { createAsyncThunk } from '@reduxjs/toolkit';

const baseURL = process.env.REACT_APP_BASE_URL

export const register = createAsyncThunk(
	'user/register',
	async ({ name, address, email, password, phone_number, subject_id }, thunkAPI) => {
		const body = JSON.stringify({
			name,
			address,
			email,
			password,
			phone_number,
			subject_id
		});

		try {
			const res = await fetch(`${baseURL}/auth/register/`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);




export const login = createAsyncThunk(
	'user/login',
	async ({ email, password }, thunkAPI) => {
		const body = JSON.stringify({
			email,
			password,
		});

		try {
			const res = await fetch(`${baseURL}/auth/login/`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			
            const data = await res.json();

			if (res.status === 200) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	}
);

