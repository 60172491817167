import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/auth';
import useScrollToTop from '../../hooks/useScrollToTop'
import { saveMe } from '../../redux/features/userSlice';
import Alert from '../../components/Alert/Alert';


function Login() {

  useScrollToTop();


  const dispatch = useDispatch();

	const { loading, error, isAuthenticated, user} = useSelector(state => state.userState);

  const goTo = (user) => {
    if(user.is_admin) {
			return '/';
    }else{
			return '/instructor-records/'
		}
  }
  
  const [rememberMe, setRememberMe] = useState(true)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });


	const { email, password } = formData;

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = e => {
		e.preventDefault();

    dispatch(saveMe(rememberMe))
		dispatch(login({ email:email.trim(), password:password.trim() }));
	};

	if (isAuthenticated) return <Navigate to={goTo(user)} />;
  return (
    <section class="p-0 d-flex align-items-center position-relative overflow-hidden">
	
		<div class="container-fluid">
			<div class="row">


				{/* <!-- Right --> */}
				<div class="col-12 col-lg-6 m-auto">
					<div class="row my-5">
						<div class="col-sm-10 col-xl-8 m-auto">
							{/* <!-- Title --> */}
							<span class="mb-0 fs-1 d-block d-sm-none">👋</span>
							<h1 class="fs-2">سجل الدخول!</h1>
							<p class="lead mb-4">سعيدين بتواجدك معنا سجل دخولك الآن.</p>
              {error?.message ?<div class="mb-4"> <Alert text='الايميل أو كلمة المرور غير صالحة' type='error' /> </div>: null }

							{/* <!-- Form START --> */}
							<form>
								{/* <!-- Email --> */}
								<div class="mb-4">
									<label for="exampleInputEmail1" class="form-label">الإيميل *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="bi bi-envelope-fill"></i></span>
										<input 
                      type="email" 
                      name="email" 
                      value={formData.email}
                      class="form-control border-0 bg-light rounded-end ps-1" 
                      placeholder="الايميل" 
                      id="exampleInputEmail1"
					  required
                      onChange={onChange}
                      />
									</div>
								</div>
								{/* <!-- Password --> */}
								<div class="mb-4">
									<label for="inputPassword5" class="form-label">كلمة المرور *</label>
									<div class="input-group input-group-lg">
										<span class="input-group-text bg-light rounded-start border-0 text-secondary px-3"><i class="fas fa-lock"></i></span>
										<input 
                      type="password" 
                      name="password" 
                      value={formData.password}
                      class="form-control border-0 bg-light rounded-end ps-1" 
                      placeholder="كلمة المرور" 
                      id="inputPassword5" 
					  required
                      onChange={onChange}
                      />
									</div>
									<div id="passwordHelpBlock" class="form-text">
										يفضل أن تكون كلمة المرور مكونة من أكثر من ثمانية خانات 
									</div>
								</div>
								{/* <!-- Check box --> */}
								<div class="mb-4 d-flex justify-content-between mb-4">
									<div class="form-check">
                  <input 
                      type="checkbox" 
                      class="form-check-input" 
                      id="exampleCheck1"
                      value={rememberMe}
                      checked={rememberMe}
                      onChange={()=> setRememberMe(prv=>!prv)}
                      />
										<label class="form-check-label" for="exampleCheck1">تذكرني</label>
									</div>
									<div class="text-primary-hover">
										<Link to='/forgot-password'class="text-secondary">
											<u>نسيت كلمة المرور؟</u>
										</Link>
									</div>
								</div>

								{/* <!-- Button --> */}
								<div class="align-items-center mt-0">
									<div class="d-grid">
										{loading ?
                    <button class="btn btn-primary-soft mb-0" type="button" disabled>
                      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      يتم التحميل...
                    </button> :
											<button onClick={onSubmit} class="btn btn-primary mb-0" type="button">سجل دخول</button>
                    }
									</div>
								</div>
							</form>
							{/* <!-- Form END --> */}



							{/* <!-- Sign up link --> */}
							<div class="mt-4 text-center">
								<span>ليس لديك حساب؟ <Link to='/signup'>سجل من هنا</Link></span>
							</div>
						</div>
                    </div>
					{/*  <!-- Row END --> */}
				</div>
			</div>
             {/* <!-- Row END --> */}
		</div>
	</section>
  )
}

export default Login