import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './features/toastSlice';
import userReducer from './features/userSlice';
import { publicApiSlice } from './api/publicApiSlice';
import { apiSlice } from './api/apiSlice';




export const store = configureStore({
	reducer: {
		userState: userReducer,
		toastState: toastReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
		[publicApiSlice.reducerPath]: publicApiSlice.reducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(apiSlice.middleware, publicApiSlice.middleware),
	devTools: process.env.NODE_ENV !== 'production',
});


export default store