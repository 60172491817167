import React from 'react'
import { Link } from 'react-router-dom'
import  glasses  from '../../assets/images/mine/3d-glasses.png'
import Profile from './Profile'

function Topbar() {
  return (
    <nav class="navbar top-bar navbar-light border-bottom py-0 py-xl-3">
		<div class="container-fluid p-0">
			<div class="d-flex align-items-center w-100">

				{/* <!-- Logo START --> */}
				<div class="d-flex align-items-center d-xl-none">
					<Link to='/' class="navbar-brand">
						<img class="light-mode-item navbar-brand-item h-30px" src={glasses} alt=""/>
					</Link>
				</div>
				{/* <!-- Logo END --> */}

				{/* <!-- Toggler for sidebar START --> */}
				<div class="navbar-expand-xl sidebar-offcanvas-menu">
					<button class="navbar-toggler me-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar" aria-expanded="false" aria-label="Toggle navigation" data-bs-auto-close="outside">
						<i class="bi bi-text-right fa-fw h2 lh-0 mb-0 rtl-flip" data-bs-target="#offcanvasMenu"> </i>
					</button>
				</div>
				{/* <!-- Toggler for sidebar END --> */}
				
				{/* <!-- Top bar left --> */}
				<div class="navbar-expand-lg ms-auto ms-xl-0">
					
					{/* <!-- Toggler for menubar START --> */}
					<button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopContent" aria-controls="navbarTopContent" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-animation">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</button>
					{/* <!-- Toggler for menubar END --> */}

					{/* <!-- Topbar menu START --> */}
					<div class="collapse navbar-collapse w-100" id="navbarTopContent">
						{/* <!-- Top search START --> */}
						<div class="nav my-3 my-xl-0 flex-nowrap align-items-center">
							<div class="nav-item w-100">
								<form class="position-relative">
									<input class="form-control pe-5 bg-secondary bg-opacity-10 border-0" type="search" placeholder="بحث" aria-label="Search"/>
									<button class="bg-transparent px-2 py-0 border-0 position-absolute top-50 end-0 translate-middle-y" type="submit"><i class="fas fa-search fs-6 text-primary"></i></button>
								</form>
							</div>
						</div>
						{/* <!-- Top search END --> */}
					</div>
					{/* <!-- Topbar menu END --> */}
				</div>
				{/* <!-- Top bar left END --> */}
				
				{/* <!-- Top bar right START --> */}
				<div class="ms-xl-auto">
					<ul class="navbar-nav flex-row align-items-center">


						{/* <!-- Profile dropdown START --> */}
						<li class="nav-item ms-2 ms-md-3 dropdown">
                        <Profile/>
						</li>
						{/* <!-- Profile dropdown END --> */}
					</ul>
				</div>
				{/* <!-- Top bar right END --> */}
			</div>
		</div>
	</nav>
  )
}

export default Topbar